<template>
  <v-row class="mt-1">
    <v-col class="d-flex flex-column align-center">
      <h1 class="primary--text">{{ $t("joinTheCommunity") }}</h1>
      <h3 class="secondary--text">{{ $t("joinTheCommunityText") }}</h3>

      <v-card class="mt-5 d-none d-sm-flex" elevation="2" shaped>
        <v-simple-table fixed-header>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="white"></th>
                <th
                  class="primary text-center white--text"
                  v-for="level in levels"
                  :key="level.name"
                >
                  <span class="d-flex flex-column pa-1">
                    <v-icon :color="level.color"> mdi-diamond-stone </v-icon>
                    {{ $t(level.name) }}
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(plan, index) in plans" :key="index">
                <td class="primary lighten-3">{{ $t(plan.name) }}</td>
                <td class="text-center" v-for="n in 5" :key="index - n">
                  <v-icon
                    class="success--text"
                    v-if="n + plan.level > levels.length"
                  >
                    mdi-check-circle
                  </v-icon>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th class="font-italic">*{{ $t("planAnnotation") }}</th>
                <td class="primary text-center white--text" :colspan="levels.length">
                  <h5>{{ $t("contactUs") }}</h5>
                </td>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
      </v-card>

      <v-card
        class="mx-auto my-12 d-sm-none"
        width="374"
        elevation="2"
        outlined
        v-for="(level, i) in levels"
        :key="level.name"
      >
        <v-row align="center" justify="center">
          <v-icon x-large :color="level.color"> mdi-diamond-stone </v-icon>
        </v-row>

        <v-card-title>{{ $t(level.name) }}</v-card-title>

        <div class="mx-4 text-subtitle-1 font-italic" v-if="i != 0">
          {{ $t(levels[i - 1].name) }} {{ $t("afterLevelAndMore") }}
        </div>

        <v-card-text>
          <div v-for="(plan, index) in plans" :key="index">
            <span v-if="level.id === plan.level">{{ $t(plan.name) }}</span>
          </div>
        </v-card-text>

        <v-divider class="mx-4"></v-divider>

        <v-card-text class="d-flex justify-center"> <h5>{{ $t("contactUs") }}</h5> </v-card-text>

        <v-card-actions>
          <span class="text--disabled font-weight-thin font-italic">
            *{{ $t("planAnnotation") }}
          </span>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "JoinTheCommunity",

  data: () => ({
    levels: [
      { id: 5, name: "levelFree", color: "brown" },
      {
        id: 4,
        name: "levelEmerald",
        color: "green lighten-3",
      },
      {
        id: 3,
        name: "levelRuby",
        color: "red",
      },
      {
        id: 2,
        name: "levelJade",
        color: "green darken-1",
      },
      { id: 1, name: "levelDiamond", color: "cyan" },
    ],
    plans: [
      {
        name: "plan01",
        level: 5,
      },
      {
        name: "plan02",
        level: 4,
      },
      {
        name: "plan03",
        level: 3,
      },
      {
        name: "plan04",
        level: 3,
      },
      {
        name: "plan05",
        level: 2,
      },
      {
        name: "plan06",
        level: 2,
      },
      {
        name: "plan07",
        level: 2,
      },
      {
        name: "plan08",
        level: 1,
      },
      {
        name: "plan09",
        level: 1,
      },
      {
        name: "plan10",
        level: 1,
      },
    ],
  }),
};
</script>

<style scoped>
td {
  border: none !important;
}
</style>