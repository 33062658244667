import Vue from 'vue'
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, addDoc } from "firebase/firestore";

const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = {
    instance: getFirestore(app),
    collection,
    addDoc,
}


Vue.use({
    install(Vue) {
        Vue.prototype.$app = app;
        Vue.prototype.$analytics = analytics;
        Vue.prototype.$db = db;
    }
})
