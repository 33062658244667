<template>
  <v-carousel cycle hide-delimiters>
    <v-carousel-item v-for="(panel, i) in panels" :key="i">
      <v-sheet height="100%" tile>
        <v-parallax
          dark
          :src="require(`@/assets/${panel.image}`)"
        >
          <v-row class="fill-height" align="end" justify="end">
            <v-card
              class="pa-3 mb-5 mx-1 white--text font-weight-thin"
              color="rgba(1, 1, 1, 0.5)"
            >
              <h4>{{ $t(panel.text) }}</h4>
            </v-card>
          </v-row>
        </v-parallax>
      </v-sheet>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  name: "HomePanel",

  data: () => ({
    panels: [
      { image: "colibri-born.jpg", text: "colibriBorn" },
      { image: "colibri-history.jpg", text: "colibriHistory" },
      { image: "colibri-reason.jpg", text: "colibriReason" },
    ],
  }),
};
</script>

<style>
</style>