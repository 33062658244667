<template>
  <v-row class="mt-2" align="center" justify="center">
    <v-col>
      <h1 class="d-flex justify-center">
        {{ $t("mainFeatures") }}
      </h1>
      <v-row>
        <v-col
          v-for="feature in features"
          :key="feature.key"
          cols="12"
          sm="4"
        >
          <feature-details class="ma-5" :image="feature.image" :messageKey="feature.key" />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import FeatureDetails from "@/components/feature-details/FeatureDetails.vue";

export default {
  name: "HomeFeatures",

  components: {
    FeatureDetails,
  },

  data: () => ({
    features: [
      { image: "cloud", key: "MultiCloud" },
      { image: "message", key: "Messaging" },
      { image: "database", key: "Database" },
      { image: "folder", key: "Storage" },
      { image: "web", key: "Rest" },
      { image: "flash", key: "FastDevelopment" },
    ],
  }),
};
</script>

<style>
</style>