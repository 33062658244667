import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const messages = {
    'en': {
        home: 'Homepage',
        colibriBorn: 'The Colibri Project was born with the aim of giving developers more productivity, quality and standardization.',
        colibriHistory: 'And why the name Colibri? Colibri is a bird that in some places is also known as a hummingbird, and the constant beating of its wings is a symbol of infinity, eternity and continuity.',
        colibriReason: `And that's exactly what we want to provide you with, that what you do is something that leaves a legacy and is easy to continue for anyone.`,
        translate: 'Language',
        clickHere: 'Click here.',
        mainFeatures: `Main features`,
        featureTitleMultiCloud: 'Multiple clouds',
        featureTextMultiCloud: 'Our SDK integrates with the main clouds on the market. They are AWS, GCP and Azure...',
        featureTitleMessaging: 'Messaging',
        featureTextMessaging: 'Our SDK integrates with leading cloud provider messaging tool. AWS SNS/SWS, GCP PUB/SUB and Azure Service Bus...',
        featureTitleDatabase: 'Database',
        featureTextDatabase: 'Our SDK integrates with PostgreSQL and the principal cloud provider NoSQL database. AWS DynamoDB, GCP Firestore and Azure Cosmos DB...',
        featureTitleStorage: 'Storage',
        featureTextStorage: 'Our SDK integrates with with leading cloud provider storage tool. AWS S3, GCP Storage and Azure Cosmos DB...',
        featureTitleRest: 'Rest',
        featureTextRest: 'Our SDK has a powerful server and a flexible client with easy configuration and impressive response time...',
        featureTitleFastDevelopment: 'Agile development',
        featureTextFastDevelopment: 'Our SDK was thought from the beginning to make life easier for the developer. With a single line, you can do a lot things...',
        whoAreWe: `Who are we`,
        creator: `Creator`,
        coCreator: `Co-Creator`,
        coFounder: `Co-Founder`,
        missionVisionValues: 'Mission, vision and values',
        mission: 'To be the best programming and methodology community, fulfilling the duty to generate learning and productivity.',
        vision: 'Provide people with the means to become better professionals through our ecosystem.',
        values: 'Collaboration, Innovation and Productivity.',
        joinTheCommunity: `Join the community`,
        joinTheCommunityText: 'Be part of this community and benefit from it. Find out how to take part:',
        afterLevelAndMore: 'and more:',
        levelFree: 'Free',
        levelEmerald: 'Emerald',
        levelRuby: 'Ruby',
        levelJade: 'Jade',
        levelDiamond: 'Diamond',
        contactUs: 'Contact us for more details',
        plan01: 'Free access to documentation',
        plan02: 'Asynchronous technical support',
        plan03: 'Remote group consulting*',
        plan04: 'Group mentoring in agile methodologies*',
        plan05: 'Pull Request Priority',
        plan06: 'Access to exclusive repositories',
        plan07: 'Exclusive support',
        plan08: 'Be part of the board',
        plan09: 'Remote one-on-one consulting*',
        plan10: 'Individual mentoring in agile methodologies*',
        planAnnotation: '1x in month',
        wePartners: `We partners`,
        partnersPromotional: 'Want to know more about the company and its open positions?',
        partnerContaAzul: `Conta Azul is a cloud ERP that simplifies your financial management. It was the company that allowed a Go Language experiment to take place in its ecosystem and after this experiment, Go was validated as a stack to be used.\n
            From that moment on, the need to help other companies arose, giving rise to this project.\n
            Thank you Conta Azul for supporting the Dev community.`,
        contact: 'Contact us',
        contactName: 'Name',
        contactNameIsRequired: 'Name is required',
        contactNameValidation: 'Name must be between 5 and 20 characters',
        contactCompany: 'Company',
        contactCompanyIsRequired: 'Company is required',
        contactCompanyValidation: 'Company must be between 5 and 20 characters',
        contactEmail: 'E-mail',
        contactEmailIsRequired: 'E-mail is required',
        contactEmailValidation: 'E-mail must be valid',
        contactSend: 'Send',
        contactSendedWithSuccess: 'Your contact details have been sent successfully',
        launchTitle: 'Launch',
        launchBasePackage: 'Base package',
        launchApplicationName: 'Application Name',
        launchApplicationType: 'Application Type',
        launchCloud: 'Cloud',
        launchFeatures: 'Features',
    },
    'es': {
        home: 'Página principal',
        colibriBorn: 'El proyecto Colibri nació con el objetivo de brindar a los desarrolladores más productividad, calidad y estandarización.',
        colibriHistory: '¿Y por qué el nombre de colibrí? Colibrí es un ave que en algunos lugares también se conoce como colibrí, y el constante batir de sus alas es símbolo de infinito, eternidad y continuidad.',
        colibriReason: 'Y eso es exactamente lo que queremos brindarte, que lo que haces sea algo que deje un legado y sea fácil de continuar para cualquiera.',
        translate: 'Idioma',
        clickHere: 'Haga clic aquí.',
        mainFeatures: `Principales features`,
        featureTitleMultiCloud: 'Múltiples nubes',
        featureTextMultiCloud: 'Nuestro SDK se integra con las principales nubes del mercado. They are AWS, GCP and Azure...',
        featureTitleMessaging: 'Mensajería',
        featureTextMessaging: 'Nuestro SDK se integra con la herramienta de mensajería líder del proveedor de la nube. AWS SNS/SWS, GCP PUB/SUB and Azure Service Bus...',
        featureTitleDatabase: 'Base de datos',
        featureTextDatabase: 'Nuestro SDK se integra con PostgreSQL y la base de datos NoSQL líder del proveedor de la nube. AWS DynamoDB, GCP Firestore and Azure Cosmos DB...',
        featureTitleStorage: 'Almacenamiento',
        featureTextStorage: 'Nuestro SDK se integra con la herramienta de almacenamiento líder en la nube. AWS S3, GCP Storage and Azure Cosmos DB...',
        featureTitleRest: 'Rest',
        featureTextRest: 'Nuestro SDK tiene un servidor potente y un cliente flexible con una configuración sencilla y un tiempo de respuesta impresionante...',
        featureTitleFastDevelopment: 'desarrollo ágil',
        featureTextFastDevelopment: 'Nuestro SDK fue pensado desde el principio para hacer la vida más fácil al desarrollador. Con una sola línea, puedes hacer mucho...',
        whoAreWe: `Quienes somos nosotros`,
        creator: `Creador`,
        coCreator: `Co-Creador`,
        coFounder: `Co-Fundador`,
        missionVisionValues: 'Misión, visión y valores',
        mission: 'Ser la mejor comunidad de programación y metodología, cumpliendo con el deber de generar aprendizaje y productividad.',
        vision: 'Proporcionar a las personas los medios para convertirse en mejores profesionales a través de nuestro ecosistema.',
        values: 'Colaboración, Innovación y Productividad.',
        joinTheCommunity: `Unete a la communidad`,
        joinTheCommunityText: 'Se parte de esta comunidad y benefíciate de ella. Descubre cómo participar:',
        afterLevelAndMore: 'y más:',
        levelFree: 'Libre',
        levelEmerald: 'Esmeralda',
        levelRuby: 'Rubí',
        levelJade: 'Jade',
        levelDiamond: 'Diamante',
        contactUs: 'Contactenos para mas detalles',
        plan01: 'Acceso gratuito a la documentación',
        plan02: 'Soporte técnico asíncrono',
        plan03: 'Consultoría grupal remota*',
        plan04: 'Mentoría grupal en metodologías ágiles*',
        plan05: 'Prioridade en pull request',
        plan06: 'Acceso a repositorios exclusivos',
        plan07: 'Soporte exclusivo',
        plan08: 'Ser parte de la junta',
        plan09: 'Consultoría remota uno a uno*',
        plan10: 'Mentoría individual en metodologías ágiles*',
        planAnnotation: '1x en mes',
        wePartners: `Nuestros asociados`,
        partnersPromotional: '¿Quiere saber más sobre la empresa y sus posiciones abiertas?',
        partnerContaAzul: `Conta Azul es un ERP en la nube que simplifica tu gestión financiera. Fue la empresa la que permitió que se llevara a cabo un experimento de Go Language en su ecosistema y, después de este experimento, Go se validó como una pila para usar.\n
            A partir de ese momento surgió la necesidad de ayudar a otras empresas, dando origen a este proyecto.\n
            Gracias Conta Azul por apoyar a la comunidad Dev.`,
        contact: 'Contáctenos',
        contactName: 'Nombre',
        contactNameIsRequired: 'Se requiere el nombre',
        contactNameValidation: 'El nombre debe tener entre 5 y 20 caracteres',
        contactCompany: 'Empresa',
        contactCompanyIsRequired: 'Se requiere empresa',
        contactCompanyValidation: 'La empresa debe tener entre 5 y 20 caracteres',
        contactEmail: 'E-mail',
        contactEmailIsRequired: 'E-mail es requerido',
        contactEmailValidation: 'El e-mail debe ser válido',
        contactSend: 'Mandar',
        contactSendedWithSuccess: 'Tus datos de contacto han sido enviados con éxito',
        launchTitle: 'Launch',
        launchBasePackage: 'Base package',
        launchApplicationName: 'Nome da Aplicacion',
        launchApplicationType: 'Tipo de Aplicacion',
        launchCloud: 'Cloud',
        launchFeatures: 'Features',
    },
    'pt-BR': {
        home: 'Página principal',
        colibriBorn: 'O projeto colibri nasceu com o objetivo de dar mais produtividade, qualidade e padronização as pessoas desenvolvedoras.',
        colibriHistory: 'E por que o nome colibri? Colibri é um pássaro que em alguns lugares é também conhecido como beija-flor, e o bater constante de suas asas é um símbolo do infinito, da eternidade e da continuidade.',
        colibriReason: 'E é exatamente isso que queremos te proporcionar, que aquilo que você faça seja algo que deixe um legado e que seja de fácil continuidade para qualquer pessoa.',
        translate: 'Idioma',
        clickHere: 'Clique aqui.',
        mainFeatures: `Principais features`,
        featureTitleMultiCloud: 'Múltiplas nuvens',
        featureTextMultiCloud: 'Nosso SDK integra-se com as principais nuvens do mercado. São elas AWS, GCP e Azure...',
        featureTitleMessaging: 'Mensageria',
        featureTextMessaging: 'Nosso SDK integra-se com as principais ferramentas de mensageria do provedor de nuvem. AWS SNS/SWS, GCP PUB/SUB and Azure Service Bus...',
        featureTitleDatabase: 'Banco de dados',
        featureTextDatabase: 'Nosso SDK integra-se ao PostgreSQL e ao banco de dados NoSQL padrão do provedor de nuvem. AWS DynamoDB, GCP Firestore e Azure Cosmos DB...',
        featureTitleStorage: 'Armazenamento',
        featureTextStorage: 'Nosso SDK integra-se com as principais ferramentas de armazenamento do provedor de nunvem. AWS S3, GCP Storage and Azure Cosmos DB...',
        featureTitleRest: 'Rest',
        featureTextRest: 'Nosso SDK possue um servidor poderossimo e um cliente flexível de fácil configuração e tempo de reposta impressionante...',
        featureTitleFastDevelopment: 'Desenvolvimento ágil',
        featureTextFastDevelopment: 'Nosso SDK foi pensando desde o principio em facilitar a vida do desenvolvedor. Com uma única linha, dá para fazer muita coisa...',
        whoAreWe: `Quem somos nós`,
        creator: `Criador`,
        coCreator: `Co-Criador`,
        coFounder: `Co-Fundador`,
        missionVisionValues: 'Missão, visão e valores',
        mission: 'Ser a melhor comunidade de programação e metodologia, cumprindo o dever de gerar aprendizado e produtividade.',
        vision: 'Disponibilizar as pessoas meios para se tornarem melhores profissionais através do nosso ecossistema.',
        values: 'Colaboração, Inovação e Produtividade.',
        joinTheCommunity: `Junte-se à comunidade`,
        joinTheCommunityText: 'Faça parte dessa comunidade e tenha benefícios. Saiba como fazer parte:',
        afterLevelAndMore: 'e mais:',
        levelFree: 'Livre',
        levelEmerald: 'Esmeralda',
        levelRuby: 'Rubi',
        levelJade: 'Jade',
        levelDiamond: 'Diamante',
        contactUs: 'Fale conosco para mais detalhes',
        plan01: 'Acesso livre a documentação',
        plan02: 'Suporte técnico assíncrono',
        plan03: 'Consultoria remota em grupo*',
        plan04: 'Mentoria em grupo em metodologias ágeis*',
        plan05: 'Prioridade em pull request',
        plan06: 'Acesso a repositórios exclusivos',
        plan07: 'Suporte exclusivo',
        plan08: 'Fazer parte do conselho',
        plan09: 'Consultoria individual remota*',
        plan10: 'Mentoria individual em metodologias ágeis*',
        planAnnotation: '1x no mês',
        wePartners: `Nossos parceiros`,
        partnersPromotional: 'Quer saber mais sobre a empresa e suas vagas em aberto?',
        partnerContaAzul: `A Conta Azul é um ERP em nuvem que simplifica sua gestão financeira. Foi a empresa que permitiu com que um experimento da Linguagem Go acontecesse em seu ecossistema e após esse experimento, o Go foi validado como uma stack a ser utilizada.\n
            A partir desse momento, surgiu a necessidade de ajudar outras empresas, dando origem a esse projeto.\n
            Obrigado Conta Azul por apoiar a comunidade Dev.`,
        contact: 'Contate-nos',
        contactName: 'Nome',
        contactNameIsRequired: 'Nome é requerido',
        contactNameValidation: 'Nome deve ter entre 5 e 20 caracteres',
        contactCompany: 'Empresa',
        contactCompanyIsRequired: 'Empresa é requerido',
        contactCompanyValidation: 'Empresa deve ter entre 5 e 20 caracteres',
        contactEmail: 'E-mail',
        contactEmailIsRequired: 'E-mail é requerido',
        contactEmailValidation: 'E-mail dever ser válido',
        contactSend: 'Enviar',
        contactSendedWithSuccess: 'Seus dados de contato foram enviados com sucesso',
        launchTitle: 'Launch',
        launchBasePackage: 'Base package',
        launchApplicationName: 'Nome da Aplicação',
        launchApplicationType: 'Tipo da aplicação',
        launchCloud: 'Cloud',
        launchFeatures: 'Features',
    },
};

const i18n = new VueI18n({
    locale: navigator.language || navigator.userLanguage,
    fallbackLocale: 'es',
    messages,
});

export default i18n;