<template>
  <div>
    <v-btn
      v-for="media in medias"
      :key="media.icon"
      class="mx-4 white--text"
      icon
      :href="media.url"
      target="_blank"
    >
      <v-icon size="24px">
        {{ media.icon }}
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "SocialMediaChooser",

  data: () => ({
    medias: [
      { icon: "mdi-instagram", url: "https://www.instagram.com/colibriproject.io" },
      { icon: "mdi-linkedin", url: "https://www.linkedin.com/in/colibri-project" },
    ],
  }),
};
</script>
