<template>
  <v-app>
    <v-app-bar app elevation="4" class="primary lighten-1 white--text">
      <v-btn color="white" icon @click.stop="drawer = !drawer">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      <v-avatar size="50">
        <v-img :aspect-ratio="16 / 9" :src="require('@/assets/colibri.png')" />
      </v-avatar>
      <v-toolbar-title>
        <router-link to="/" style="text-decoration: none; color: inherit;"> Colibri Project </router-link>
      </v-toolbar-title>

      <v-spacer />

      <div>
        <v-btn color="white" to="/launch"><v-icon left>mdi-rocket-launch</v-icon>Launch</v-btn>
        <v-btn
          color="white"
          icon
          href="https://github.com/colibri-project-io"
          target="_blank"
        >
          <v-icon>mdi-github</v-icon>
        </v-btn>
        <language-chooser />
      </div>
    </v-app-bar>

    <v-main class="grey lighten-5">
      <v-navigation-drawer v-model="drawer" absolute temporary>
        <v-list-item link to="/">
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ $t("home") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list dense>
          <v-list-item
            v-for="item in items"
            :key="item.title"
            link
            :to="item.url"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>

    <v-footer
      padless
      class="primary lighten-1 white--text pa-2 justify-center justify-sm-space-between justify-md-space-between justify-lg-space-between justify-xl-space-between"
    >
      <div class="d-flex flex-1">
        Copyright © {{ new Date().getFullYear() }} — Colibri Project
      </div>

      <div>
        <social-media-chooser />
      </div>
    </v-footer>
  </v-app>
</template>

<script>
import LanguageChooser from "@/components/language-chooser/LanguageChooser.vue";
import SocialMediaChooser from "@/components/social-media-chooser/SocialMediaChooser.vue";

export default {
  name: "App",

  components: {
    LanguageChooser,
    SocialMediaChooser,
  },

  data: () => ({
    drawer: null,
    items: [
      {
        title: "joinTheCommunity",
        icon: "mdi-forum",
        url: "/join-the-community",
      },
      { title: "wePartners", icon: "mdi-handshake", url: "/we-partners" },
      {
        title: "whoAreWe",
        icon: "mdi-account-multiple",
        url: "/who-are-we",
      },
      {
        title: "contact",
        icon: "mdi-card-account-mail",
        url: "/contact-us",
      },
    ],
  }),
};
</script>

<style>
.break-line {
  white-space: pre-line;
}
</style>