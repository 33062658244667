import Vue from 'vue'
import App from '@/core/App.vue'
import vuetify from '@/core/vuetify'
import i18n from '@/core/i18n'
import router from '@/core/router'

import '@/core/configs'
import '@/core/firebase'

Vue.config.productionTip = false

new Vue({
  i18n,
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
