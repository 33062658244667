<template>
  <v-row class="mt-1">
    <v-col class="d-flex flex-column align-center">
      <h1 class="primary--text mb-10">{{ $t("whoAreWe") }}</h1>

      <v-col class="d-flex flex-wrap justify-center">
        <v-card
          class="ma-5"
          width="300"
          elevation="2"
          outlined
          gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
          v-for="founder in founders"
          :key="founder.id"
        >
          <v-card-title class="d-flex justify-center">
            <v-img
              max-width="200px"
              :src="require(`@/assets/${founder.photo}`)"
            />
          </v-card-title>

          <v-card-text class="d-flex flex-column align-center">
            <span class="text-h6">{{ $t(founder.name) }}</span>
            <v-col class="d-flex justify-center">
              <v-chip
                class="mr-1"
                v-for="(profile, key) in founder.profiles"
                :key="key"
              >
                <span class="blue--text">
                  {{ $t(profile) }}
                </span>
              </v-chip>
            </v-col>
          </v-card-text>

          <v-divider class="mx-4"></v-divider>
          <v-card-actions class="d-flex justify-center">
            <v-btn
              class="mx-4 blue--text"
              icon
              :href="founder.linkedIn"
              target="_blank"
            >
              <v-icon size="32px">mdi-linkedin</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <h1 class="primary--text mx-10">{{ $t("missionVisionValues") }}</h1>
      <h4 class="secondary--text mb-3"> {{ $t("mission") }} </h4>
      <h4 class="secondary--text mb-3"> {{ $t("vision") }} </h4>
      <h4 class="secondary--text mb-3"> {{ $t("values") }} </h4>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "WhoAreWe",

  data: () => ({
    founders: [
      {
        id: 1,
        name: "Rafael Dantas",
        photo: "dantas.jpeg",
        profiles: ["creator", "coFounder"],
        linkedIn: "https://www.linkedin.com/in/rafael-dantas-a0ab7248/",
      },
      {
        id: 2,
        name: "Rafael Ramos",
        photo: "ramos.jpeg",
        profiles: ["coCreator", "coFounder"],
        linkedIn: "https://www.linkedin.com/in/rafaelramosoliveira/",
      },
      {
        id: 3,
        name: "Rafael Lima",
        photo: "lima.jpeg",
        profiles: ["coFounder"],
        linkedIn: "https://www.linkedin.com/in/rafael-lima-silva-professional/",
      },
      {
        id: 4,
        name: "Júnior Zilli",
        photo: "zilli.jpeg",
        profiles: ["coFounder"],
        linkedIn: "https://www.linkedin.com/in/azillidade/",
      },
    ],
  }),
};
</script>

<style>
</style>