<template>
  <v-row class="mt-1">
    <v-col class="d-flex flex-column align-center">
      <h1 class="primary--text">{{ $t("wePartners") }}</h1>

      <v-card
        class="mx-auto my-12"
        width="400"
        elevation="2"
        outlined
        v-for="partner in partners"
        :key="partner.id"
      >
        <v-card-title>
          <v-img class="ma-4" :src="require(`@/assets/${partner.logo}`)" />
        </v-card-title>

        <v-card-text class="d-flex justify-center">
          <span class="break-line">{{ $t(partner.description) }}</span>
        </v-card-text>

        <v-divider class="mx-4"></v-divider>

        <v-card-actions>
          <span class="text--disabled font-weight-thin font-italic">
            {{ $t("partnersPromotional") }}
            <a :href="partner.link" target="_blank">{{ $t("clickHere") }}</a>
          </span>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "WePartners",

  data: () => ({
    partners: [
      {
        id: 1,
        name: "Conta Azul",
        logo: "partners-conta-azul.svg",
        link: "https://www.contaazul.com",
        description: "partnerContaAzul",
      },
    ],
  }),
};
</script>

<style>
</style>