import Vue from 'vue'
import Router from 'vue-router'

import Home from '@/pages/home/Home.vue'
import JoinTheCommunity from '@/pages/join-the-community/JoinTheCommunity.vue'
import WePartners from '@/pages/we-partners/WePartners.vue'
import WhoAreWe from '@/pages/who-are-we/WhoAreWe.vue'
import ContactUs from '@/pages/contact-us/ContactUs.vue'
import Launch from "@/pages/launch/Launch.vue";

Vue.use(Router)
const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '*',
            redirect: '/'
        },
        {
            path: '/',
            component: Home,
        },
        {
            path: '/join-the-community',
            component: JoinTheCommunity,
        },
        {
            path: '/we-partners',
            component: WePartners,
        },
        {
            path: '/who-are-we',
            component: WhoAreWe,
        },
        {
            path: '/contact-us',
            component: ContactUs,
        },
        {
            path: '/launch',
            component: Launch,
        },
    ]
});

export default router