import Vue from 'vue'

const configs = {
    recaptchaKey: process.env.VUE_APP_RECAPTCHA_KEY,
};

Vue.use({
    install(Vue) {
        Vue.prototype.$configs = configs;
    }
})
