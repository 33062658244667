<template>
  <v-row class="mt-1">
    <v-col class="d-flex flex-column align-center">
      <h1 class="primary--text mb-10">{{ $t("contact") }}</h1>

      <v-col class="d-flex flex-wrap justify-center">
        <v-card elevation="2" width="500px">
          <v-card-text>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              class="flex flex-1"
            >
              <v-text-field
                v-model="form.name"
                minlength="5"
                maxlength="20"
                :counter="20"
                :rules="nameRules"
                :label="$t('contactName')"
                required
              ></v-text-field>

              <v-text-field
                v-model="form.company"
                minlength="5"
                maxlength="20"
                :counter="20"
                :rules="companyRules"
                :label="$t('contactCompany')"
                required
              ></v-text-field>

              <v-text-field
                v-model="form.email"
                :rules="emailRules"
                :label="$t('contactEmail')"
                required
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-col align="center">
              <vue-recaptcha
                ref="recaptcha"
                @verify="onRecaptchaVerified"
                @expired="onRecaptchaExpired"
                :sitekey="recaptchaKey"
              />
              <v-btn color="primary" class="mt-3" @click="send">
                {{ $t("contactSend") }}
              </v-btn>
            </v-col>
          </v-card-actions>
        </v-card>
        <v-snackbar
          timeout="3000"
          v-model="snackbar.show"
          absolute
          bottom
          :color="snackbar.color"
          outlined
          right
        >
          {{ snackbar.msg }}
        </v-snackbar>
      </v-col>
    </v-col>
  </v-row>
</template>

<script>
import { VueRecaptcha } from "vue-recaptcha";

export default {
  name: "ContactUs",

  components: { VueRecaptcha },

  data: () => ({
    form: {
      name: "",
      company: "",
      email: "",
      createdAt: new Date().toISOString(),
    },
    valid: false,
    recaptchaIsValid: false,
    snackbar: {
      show: false,
      msg: "",
      color: "success",
    },
  }),

  computed: {
    recaptchaKey() {
      return this.$configs.recaptchaKey;
    },
    isEnableToSend() {
      return this.valid && this.recaptchaIsValid;
    },
    nameRules() {
      return [
        (v) => !!v || this.$t("contactNameIsRequired"),
        (v) =>
          (v && v.length >= 5 && v.length <= 20) ||
          this.$t("contactNameValidation"),
      ];
    },
    companyRules() {
      return [
        (v) => !!v || this.$t("contactCompanyIsRequired"),
        (v) =>
          (v && v.length >= 5 && v.length <= 20) ||
          this.$t("contactCompanyValidation"),
      ];
    },
    emailRules() {
      return [
        (v) => !!v || this.$t("contactEmailIsRequired"),
        (v) => /.+@.+\..+/.test(v) || this.$t("contactEmailValidation"),
      ];
    },
  },

  methods: {
    onRecaptchaVerified() {
      this.recaptchaIsValid = true;
    },
    onRecaptchaExpired() {
      this.recaptchaIsValid = false;
    },
    async send() {
      await this.$refs.form.validate();
      if (!this.isEnableToSend) {
        return;
      }

      try {
        await this.$db.addDoc(this.$db.collection(this.$db.instance, "leads"), { ...this.form });
        this.snackbar.msg = this.$t("contactSendedWithSuccess");
        this.snackbar.color = "success";
      } catch (e) {
        this.snackbar.msg = this.$t("contactSendedWithSuccess");
        this.snackbar.color = "error";
        console.error("Error on save lead: ", e);
      }

      this.snackbar.show = true;
    },
  },
};
</script>

<style>
</style>