const appendFormChild = function (form, name, value) {
  const appNameInput = document.createElement("input");
  appNameInput.type = "text";
  appNameInput.name = name;
  appNameInput.value = value;
  form.appendChild(appNameInput);
};

export default async function createProject(config) {
  const formElement = document.createElement("form");
  formElement.target = "Map";
  formElement.method = "POST";
  formElement.action =
    "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-a107a8e1-4314-4caf-9ac6-63b3090ae42b/colibri/launcher";

  appendFormChild(formElement, "appName", config.appName);
  appendFormChild(formElement, "basePackage", config.basePackage);
  appendFormChild(formElement, "cloud", config.cloud);
  appendFormChild(formElement, "type", config.type);
  appendFormChild(formElement, "features", config.features);

  document.body.appendChild(formElement);

  formElement.submit();
}
