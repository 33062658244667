<template>
  <div>
    <home-panel />
    <home-features />
  </div>
</template>

<script>
import HomePanel from "./HomePanel.vue";
import HomeFeatures from "./HomeFeatures.vue";

export default {
  name: "HomePage",

  components: {
    HomePanel,
    HomeFeatures,
  },
};
</script>
