<template>
  <v-menu bottom offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="white" icon v-bind="attrs" v-on="on">
        <v-icon>mdi-translate</v-icon>
      </v-btn>
    </template>

    <v-list>
      <v-subheader>{{ $t("translate") }}</v-subheader>
      <v-list-item-group color="primary">
        <v-list-item
          v-for="entry in languages"
          :key="entry.language"
          @click="changeLocale(entry.language)"
        >
          <v-list-item-icon>
            <v-avatar size="20">
              <v-img :src="require(`@/assets/flag-${entry.flag}.svg`)" />
            </v-avatar>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ entry.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import i18n from "@/core/i18n";

export default {
  name: "LanguageChooser",

  data: () => ({
    languages: [
      { flag: "us", language: "en", title: "English" },
      { flag: "es", language: "es", title: "Español" },
      { flag: "br", language: "pt-BR", title: "Português Brasileiro" },
    ],
  }),

  watch: {},

  methods: {
    changeLocale(locale) {
      i18n.locale = locale;
    },
  },
};
</script>

<style>
</style>