<template>
    <div>
        <v-row class="mt-1">
            <v-col class="d-flex flex-column align-center">
                <v-badge content="beta" inline> <h1 class="primary--text mb-10">{{ $t("launchTitle") }}</h1></v-badge>

                <v-col class="d-flex flex-wrap justify-center">
                    <v-text-field :label="$t('launchApplicationName')" v-model="appName"/>
                </v-col>

                <v-col class="d-flex flex-wrap justify-center">
                    <v-text-field :label="$t('launchBasePackage')" v-model="basePackage"/>
                </v-col>

                <v-col class="d-flex flex-wrap justify-center">
                    <v-select :label="$t('launchCloud')" outlined dense :items="clouds" item-text="name"
                              item-disabled="disabled" return-object v-model="selectedCloud"/>
                </v-col>

                <v-col class="d-flex flex-wrap justify-center">
                    <v-select :label="$t('launchApplicationType')" outlined dense :items="applicationTypes"
                              item-text="name"
                              item-disabled="disabled" return-object v-model="selectedApplicationType"/>
                </v-col>

                <v-col class="d-flex flex-wrap justify-center">
                    <v-select :label="$t('launchFeatures')" outlined dense :items="features" item-text="name" multiple
                              item-disabled="disabled" return-object v-model="selectedFeatures"/>
                </v-col>
            </v-col>
        </v-row>
        <v-btn @click.prevent="generate">Create</v-btn>
    </div>
</template>

<script>
import createProject from '@/service/create-project'

export default {
    name: "LaunchPage",
    data: () => ({
        clouds: [
            {name: 'AWS', code: 'aws', disabled: false},
            {name: 'GCP', code: 'gcp', disabled: false},
            {name: 'Azure', code: 'azure', disabled: true},
        ],
        applicationTypes: [
            {name: 'Application', code: 'service', disabled: false},
            {name: 'Function (serverless)', code: 'serverless', disabled: true},
        ],
        features: [
            {name: 'Redis', code: 'cachedb', disabled: false},
            {name: 'Postgres', code: 'sqldb', disabled: false},
            {name: 'Messaging', code: 'messaging', disabled: false},
            {name: 'Storage', code: 'storage', disabled: false},
            {name: 'Client Rest', code: 'restclient', disabled: false},
            {name: 'Server Rest', code: 'restserver', disabled: false},
        ],
        appName: 'application-name',
        basePackage: 'github.com/example',
        selectedCloud: '',
        selectedApplicationType: '',
        selectedFeatures: [],
    }),
    methods: {
        async generate() {
            const config = {
                appName: this.appName,
                cloud: this.selectedCloud.code,
                basePackage: this.basePackage,
                type: this.selectedApplicationType.code,
                features: this.selectedFeatures.map(item => item.code)
            };
            await createProject(config);
        }
    }
}
</script>
