<template>
  <v-hover v-slot="{ hover }" open-delay="200">
    <v-card
      :elevation="hover ? 16 : 2"
      :class="{ 'on-hover': hover }"
    >
      <v-card-title>
        <v-icon color="blue" class="mr-2">mdi-{{ image }}</v-icon>
        {{ $t(`featureTitle${messageKey}`) }}
      </v-card-title>
      <v-card-text>{{ $t(`featureText${messageKey}`) }}</v-card-text>
    </v-card>
  </v-hover>
</template>

<script>
export default {
  name: "FeatureDetails",

  props: {
    image: {
      type: String,
      require: true,
    },
    messageKey: {
      type: String,
      require: true,
    },
  },
};
</script>

<style>
</style>